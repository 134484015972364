.pop-style {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-start
}

@media only screen and (max-width: 850px) { 
    .pop-style {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start
    }
    
    .popModal {
        max-width: 90vw;
        position: relative !important;
    }
}
