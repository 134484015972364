.blink_me {
    animation: blinker 70s linear infinite;
  }
  
  @keyframes blinker {
    0% {
        opacity: 0;
      }
    5% {
      opacity: 1;
    }
    6% {
        opacity: 0;
      }
      95% {
        opacity: 1;
      }
  }

 @keyframes visibility {
  0% {
    opacity: 1;
  }
  7.14% {
    opacity: 1;
  }
  21.43% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.visible-text {
  animation: visibility 70s linear infinite;
}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}


.content-video::before {
  content: 'alaaa ';
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  top: 0;
 
  left: 0;
}

.video-overlay {
  position: absolute; 
  top: 42%;
  left: 36%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(159 139 139 / 0%);
  color: rgb(255 148 0);
  rotate: -45deg;
  opacity: 0.5;
}
/* 
@media only screen and (min-device-width : 320px) and (max-device-width : 1200px) {
  .fullscreen-overlay {
    top: 37%;
  }
} */
  